export default class ApiRequests {}

ApiRequests.exam = {
  launch: ({ client }, exam) => client.post("/api/exam/launch", exam),
  template: {
    list: ({ client }, data, params) =>
      client.post(
        "/api/exam/templates-list",
        { filters: data },
        { params: params }
      ),
    create: ({ client }, data) => client.post("/api/exam/template", data),
    edit: ({ client }, { id, data }) =>
      client.post("/api/exam/template-edit/" + id, data),
    show: ({ client }, id) => client.get("/api/exam/template-show/" + id),
  },
  questions: {
    create: ({ client }, data) => client.post("/api/exam/question", data),
    delete: ({ client }, id) => client.delete("/api/exam/question/" + id),
    update: ({ client }, { id, data }) =>
      client.post("/api/exam/question/" + id, data),
  },
  answers: {
    create: ({ client }, data) => client.post("/api/exam/answer-option", data),
    delete: ({ client }, id) => client.delete("/api/exam/answer-option/" + id),
    update: ({ client }, { id, data }) =>
      client.post("/api/exam/answer-option/" + id, data),
    saveCustomAnswerOption: ({ client }, data) =>
      client.post("/api/exam/custom-answer-option", data),
    massDelete: ({ client }, answers) =>
      client.post("/api/exam/answers-mass-delete", answers),
  },
  user_forms: {
    list: ({ client }, id) =>
      client.get("api/exam/for-evaluation-list/" + `${id ?? ""}`),
    show: ({ client }, id) => client.get("/api/exam/form/" + id),
    fillQuestion: ({ client }, { evaluationId, data }) =>
      client.post("/api/exam/form/fill-evaluation/" + evaluationId, data),
    fillForm: ({ client }, id) => client.post("/api/exam/form/fill/" + id),
    filledList: ({ client }, data) =>
      client.post("/api/exam/filled-forms-list/" + data.idUser, data),
    filledListForUser: ({ client }, data) =>
        client.post("/api/exam/filled-forms-list-for-user/" + data.idUser, data),
    filledForm: ({ client }, id) => client.get("/api/exam/form-result/" + id),
    startTest: ({ client }, id) => client.get("/api/exam/start-test/" + id),
  },
  forms: {
    filledListForAdmin: ({ client }, data) =>
      client.post("/api/exam/filled-forms-list-for-admin", data),
    export: ({ client }, data) =>
      client.post("/api/exam/export", data, { responseType: "blob" }),
  },
};
