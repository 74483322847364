export default {
  exam: {
    template: "Тест",
    continue: "Далее",
    back: "Назад",
    title: "Наименование",
    title_placeholder: "Наименование",
    description: "Описание",
    description_placeholder: "Описание",
    correct_option: "Правильный ответ",
    members: "Участники теста",
    minimum_requirements:
      "Для запуска тестирования нужно выбрать мин. одного сотрудника или отдел",
    date_start: "Дата запуска",
    date_finish: "Дата окончания",
    date_from: "с",
    date_to: "по",
    remind: "Напомнить участникам",
    before_finish_date: "за день до даты окончания",
    custom_remind_date: "своя дата напоминания",
    remind_date: "Дата напоминания",
    start_exam_notify: "Уведомить о начале тестирования",
    launch: "Запустить",
    launch_exam: "Запуск теста",
    launching: "Назначение теста пользователям",
    no_published_templates: "Нет опубликованных",
    exams_list: "Тесты",
    create: "Создать тест",
    creating: "Создание теста",
    title_main: "Главная",
    error: "Ошибка",
    status_title: "Статус",
    search_placeholder: "Введите название теста",
    save_exam: "Тест сохранен",
    exams_filled_forms: "Все результаты",
    myresults: "Мои результаты",
    tabs: {
      description: "Описание",
      structure: "Структура",
    },
    structure: {
      add: {
        question: "Добавить вопрос",
        answer_option: "Добавить вариант ответа",
      },
      mode: {
        simple: "Один вариант ответа",
        multi: "Несколько вариантов ответа",
        custom: "Свободная форма ответа",
      },
      placeholder: {
        question: "Текст вопроса",
        answer_option: "Текст варианта ответа",
        user_answer: "Ответ тестируемого",
      },
      errors: {
        cant_add_question: "Введите текст предыдущего вопроса",
        cant_add_answer_no_question: "Введите текст вопроса",
        cant_add_answer: "Введите текст предыдущего ответа",
        need_one_correct_answer:
          "Для данного типа вопроса не может быть более одного правильного ответа",
      },
    },
    exam_pass: {
      title: "Тесты на прохождение",
      no_exam: "В данном разделе тестов пока нет",
      title_deadline: "Крайний срок:",
      finish: "Завершить",
      finish_later: "Завершить позже",
      no_results: "Результатов тестов еще нет",
      date_exam: "Дата проведения теста",
      result_percent: "Доля правильных ответов",
    },
    exam_menu: {
      director: "Руководитель",
      administrator: "Администратор",
      my_results: "Мои результаты",
      help: "Помощь",
    },
    table_headers: {
      created_at: "Дата создания",
      name: "Название теста",
      filled_date: "Дата прохождения",
      exam_taker: "Тестируемый",
      exam_template: "Тест",
      result: "Результат",
      status: "Статус",
      attempts: "Попытки",
    },
    exam_btn: {
      edit: "Редактировать",
      save: "Сохранить",
    },
    statuses: {
      draft: "Черновик",
      published: "Опубликовано",
    },
    excel: {
      download: "Excel",
      fail_download: "Не удалось экспортировать в excel",
    },
    toggle_switch: {
      limitation: "Ограничить время на прохождение теста",
      min: "минут",
      sec: "секунд",
    },
    percent_limit_switcher: {
      limitation: "Требуемый процент для прохождения",
    },
    attempts_limit_switcher: {
      limitation: "Ограничить количество попыток",
    },
    additional_settings: {
      additionally: "Дополнительно",
    },
    test_modal: {
      time_limit: "Времени на прохождение теста :",
      attempts: "Попытка :",
      of: "из",
      pass_later: "Пройти позже",
      start: "Приступить",
      yes: "Да",
      no: "Нет",
      not_all_answers: "Вы не заполнили все ответы!",
      are_you_sure: "Вы уверены, что хотите завершить?",
      time_expired: "Время истекло!",
      time_final_test: "Тест завершен.",
      ok: "Ок",
      congratulations: "Поздравляем!",
      success_message: "Тест успешно пройден!",
      failed: "Тест не пройден.",
      attempts_left: "Количество оставшихся попыток:",
      test_failed: "Тест провален.",
      after_all_attempts: "Вы не смогли пройти тест после всех попыток.",
      retry_attempt: "Повторить попытку",
    },
  },
  minutes: {
    one: "минута",
    few: "минуты",
    many: "минут",
    other: "минут",
  },
  seconds: {
    one: "секунда",
    few: "секунды",
    many: "секунд",
    other: "секунд",
  },
  statuses_form: {
    accepted: "Пройден",
    passed: "Зачёт",
    failed: "Незачёт",
  },
};
